import {get, post, put, del} from './http';
import {wordsApi} from './url';

const {
    bookApi,
    chapterApi,
    wordApi,
    addCategoryApi, 
    getCategoryApi,
    updateCategoryApi,
    deleteCategoryApi,

    addWordApi,
    addUserApi,
    getMenuApi,
    getWordsApi,
    updateWordApi,
    deleteWordApi,  
} = wordsApi;

// book related
export const addBook = (params: any) => {
    return post(bookApi, params);
}
export const getBooks = (params: any) => {
    return get(bookApi, {params});
}
export const getBookById  = (params: any) => {
    return get(bookApi, {params});
}
export const updateBook = (params: any) => {
    return put(bookApi, params);
}
export const deleteBook = (params: any) => {
    return del(bookApi, {params});
}
export const getBookByKey = (params: any) => {
    return get(bookApi, {params});
}

// chapter related
export const addChapter = (params: any) => {
    return post(chapterApi, params);
}
export const getChapters = (params: any) => {
    return get(chapterApi, {params});
}
export const updateChapter = (params: any) => {
    return put(chapterApi, params);
}
export const deleteChapter = (params: any) => {
    return del(chapterApi, {params});
}
export const getChapterById = (params: any) => {
    return get(chapterApi, {params});
}
export const getChapterByKey = (params: any) => {
    return get(chapterApi, {params});
}

// word related

export const getWords = (params: any) => {
    return get(wordApi, {params});
}
export const addWord = (params: any) => {
    return post(wordApi, params);
}
export const addMutiWords = (params: any) => {
    return post(wordApi, params);
}
export const updateWord = (params: any) => {
    return put(wordApi, params);
}
export const deleteWord = (params: any) => {
    return del(wordApi, {params});
}
export const deleteMutiWords = (params: any) => {
    return post(wordApi, params)
}


export const addCategory = (params: any) => {
    return post(addCategoryApi, {params});
}
export const getCategory = (params: any) => {
    return get(getCategoryApi, {params});
}
export const updateCategory = (params: any) => {
    return post(updateCategoryApi, {params});
}
export const deleteCategory = (params: any) => {
    return post(deleteCategoryApi, {params});
}

// export const addWord = (params: any) => {
//     return post(addWordApi, params);
// }
export const addUser = (params: any) => {
    return post(addUserApi, {params});
}
export const getMenu = (params: any) => {
    return get(getMenuApi, {params});
}

// export const getWords = (params: any) => {
//     return get(getWordsApi, {params});
// }
// export const updateWord = (params: any) => {
//     return post(updateWordApi, {params});
// }
// export const deleteWord = (params: any) => {
//     return post(deleteWordApi, {params});
// }
