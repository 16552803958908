
function bookReducer (state: any, action:any) {
    switch (action.type) {
        case "CURRENT_BOOK":
            const currentBook = action.payload;
            return {...state, ...{currentBook}};
        case "CURRENT_OPERATIONAL_BOOK":
            const currentOperationalBook = action.payload;
            return {...state, ...{currentOperationalBook}};
        case "BOOK_REFRESH": 
            const bookRefresh = action.payload;
            return {...state, ...{bookRefresh}};
        case "BOOK_LOADING": 
            const bookLoading = action.payload;
            return {...state, ...{bookLoading}};
        case "BOOK_PATH":
            const bookPath = action.payload;
            return {...state, ...{bookPath}};
        case "BOOK_LIST":
            const bookList = action.payload;
            return {...state, ...{bookList}};
        default:
            return state;
    }
}

function chapterReducer (state:any ,action:any) {
    switch (action.type) {
        case "CURRENT_CHAPTER":
            const currentChapter= action.payload;
            return {...state, ...{currentChapter}};
        case "CURRENT_OPERATIONAL_CHAPTER":
            const currentOperationalChapter = action.payload;
            return {...state, ...{currentOperationalChapter}};
        case "CHAPTER_REFRESH": 
            const chapterRefresh = action.payload;
            return {...state, ...{chapterRefresh}};
        case "CHAPTER_LOADING":
            const chapterLoading = action.payload;
            return {...state, ...{chapterLoading}};
        case "CHAPTER_PATH":
            const chapterPath = action.payload;
            return {...state, ...{chapterPath}};
        case "CHAPTER_LIST":
            const chapterList = action.payload;
            return {...state, ...{chapterList}};
        default:
            return state;
    }
}
function wordReducer (state:any ,action:any) {
    switch (action.type) {
        case "WORD_LOADING":
            const wordLoading = action.payload;
            return {...state, ...{wordLoading}};
        case "WORD_REFRESH": 
            const wordRefresh = action.payload;
            return {...state, ...{wordRefresh}};
        case "CURRENT_WORD":
            const currentWord = action.payload;
            return {...state, ...{currentWord}};
        case "WORD_MODE":
            const mode = action.payload;
            return {...state, ...{mode}};
        case "ADD_WORD_EXAMPLES":
            const examples = action.payload;
            const oldCurrentWord = state.currentWord;
            return {...state, ...{currentWord: {...oldCurrentWord, examples}}};
        case "CURRENT_WORD_LIST":
            const currentWordList = action.payload;
            return {...state, ...{currentWordList}};
        case "FILTER_WORD_LIST":
            const filterWordList = action.payload;
            return {...state, ...{filterWordList}};  
        case "UPDATE_CURRENT_WORDLIST":
            const newCurrentWordList = action.payload;
            return {...state, ...{currentWordList: newCurrentWordList}};
        default:
            return state;
    }
}

function modalReducer (state:any ,action:any) {
    switch (action.type) {
        case "MODAL_TYPE":
            const type= action.payload;
            return {...state, ...{type}};
        case "MODAL_NAME":
            const name= action.payload;
            return {...state, ...{name}};
        case "MODAL_VISIBLE":
            const visible= action.payload;
            return {...state, ...{visible}};
        default:
            return state;
    }
}

function userReducer(state: any, action:any) {
    switch (action.type) {
        case "USER_INFO":
            const userInfo= action.payload;
            return {...state, ...userInfo};
        default:
            return state;
    }
}

function configReducer(state: any, action:any) {
    switch (action.type) {
        case "IS_INIT":
            const isInit= action.payload;
            return {...state, ...{isInit}};
        default:
            return state;
    }
}
function wordToolsReducer(state: any, action:any) {
    switch (action.type) {
        case "WORDS_TOOLS":
            const tools= action.payload;
            return {...state, ...tools};
        default:
            return state;
    }
}

export default function appReducer(state:any, action:any) {
    return {
        user: userReducer(state.user, action),
        book: bookReducer(state.book, action),
        chapter: chapterReducer(state.chapter, action),
        word:wordReducer(state.word, action),
        modal: modalReducer(state.modal, action),
        config: configReducer(state.config, action),
        wordTools: wordToolsReducer(state.wordTools, action),
    }
}