
import React, { Suspense, useContext } from 'react';
import {StateContext} from '../../store/contexts';
//import {wordStatus} from '../../constants/word-status'
import {Button, Empty, message, Popconfirm} from 'antd';
import {EditOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons'
import { deleteWord, deleteMutiWords } from '../../api/api';

const Aside: React.FC<{}> = () => {
    const {state:{word: {currentWord: word, currentWordList, filterWordList}, chapter:{currentChapter: chapter}}, dispatch} = useContext(StateContext);
    const wordStatus = {
        0: {
            label: '未开始',
            color: 'text-gray-500'
        },
        1: {
            label: '不认识',
            color: 'text-red-500'
        },
        2: {
            label: '模糊',
            color: 'text-yellow-500'
        },
        3: {
            label: '认识',
            color: 'text-green-500'
        }
    }
    // 修改单词
    const updateWordEvent =() => {
        dispatch({type: 'MODAL_VISIBLE', payload:true})
        dispatch({type: 'MODAL_TYPE', payload:'editWord'})
    }

    // 删除单词
    const deleteWordEvent =(_id: string) => {
        deleteWord({_id}).then((res: any) => {
            if (res.code == 0){
                message.success('delete success')
                dispatch({type:"CURRENT_WORD", payload: {}})

                const newCurrentWordList = currentWordList.filter((item:any) => {
                    return item._id !== _id;
                })
                dispatch({type:'CURRENT_WORD_LIST', payload: newCurrentWordList})

                const newFilterWordList = filterWordList.filter((item:any) => {
                    return item._id !== _id;
                })
                dispatch({type:'FILTER_WORD_LIST', payload: newFilterWordList})
            }
        })
    }

    // 新增例句
    const newExamplesEvent =() => {
        dispatch({type: 'MODAL_VISIBLE', payload:true})
        dispatch({type: 'MODAL_TYPE', payload:'newExamples'})
    }

    const deleteMutiWordsEvent = () =>  {
        const idsArr = currentWordList.map((item:any) => item._id);
        let ids = {ids: idsArr};
        deleteMutiWords(ids).then((res: any) => {
          if(res.code === 0) {
            message.success('delete success');
            dispatch({type: "WORD_REFRESH", payload: + new Date()});
          }
        })
      }

    const asideInfo = () => {
        const source = /http/.test(chapter.source) ? <a className='text-sky-600 break-words'  target="_blank" rel="noreferrer" href={chapter.source}>{chapter.source}</a> : chapter.source;
        if(chapter.key && !word.word) { 
            return <div className='p-8 ease-in-out transition-transform'>
                    <h1>章节信息</h1>
                    <div className='mt-8'>
                        <h3>{chapter.name}</h3>
                    </div>
                    {/* <div className='mt-8'>
                        <h3>添加日期</h3>
                        <p className='mt-4'>{chapter.date}</p>
                    </div> */}
                    <div className='mt-8'>
                        <h3>来源</h3>
                        <p className='mt-4'>{source || '无'}</p>
                    </div>
                    <div className='mt-8'>
                        <h3> 删除章节所有单词</h3>
                        <Popconfirm
                            title="确定要删除本章节所有单词么?"
                            onConfirm={(e:any) => {e.stopPropagation(); deleteMutiWordsEvent()}}
                            onCancel={() =>{}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger className='mt-8' >删除</Button>
                        </Popconfirm>
                        
                    </div>
                </div>
        } 
        if (word.word){
            return <div className='p-8 h-[calc(100vh-80px)] overflow-auto'>
                <div className='flex items-center'>
                    <h1 className={`flex-1 ${word.status !== undefined ?(wordStatus as any)[word.status].color : ''}`}>{word.word}</h1>
                    <p className="cursor-pointer" onClick={updateWordEvent}><EditOutlined /></p>
                    <p className="cursor-pointer ml-2">
                        <Popconfirm
                            title="确定要删除该单词么?"
                            onConfirm={(e:any) => {e.stopPropagation(); deleteWordEvent(word._id)}}
                            onCancel={() =>{}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined onClick={(e) => {e.stopPropagation()}} />
                        </Popconfirm>
                    </p>
                </div>
                <div className='mt-8'>
                    <h3>音标</h3>
                    <p>[{word.phonetic}] 👄</p>
                </div>
                <div  className='mt-8'>
                    <h3>解释</h3>
                    <ul>
                        {word.explains.map((item:any) => {
                            return <li className='mt-2' key={item}>{item}</li>;
                        })}
                    </ul>
                </div>
                <div className='mt-8'>
                    <div className='flex items-center'>
                        <h3>例句</h3>
                        <PlusOutlined className='ml-4 cursor-pointer text-sky-500' onClick={() => {
                            newExamplesEvent()
                        }}/>
                    </div>
                    {
                    word.examples ? <ul>
                        {word.examples.map((item:any) => {
                            return <li className='mt-2'>{item}</li>;
                        })}
                    </ul> : '无'
                    }
                </div> 
                <div className='mt-8'>
                    <h3>考试范围</h3>
                    <ul className='flex flex-wrap'>
                        {word.exam_type && word.exam_type.map((item:any, index:any) => {
                            return <li className='mt-2 mr-2'>
                                {index === 0 && <span className='mr-2'>[</span>}
                                {item}
                                {index === word.exam_type.length - 1 && <span className='ml-2'>]</span>}
                                </li>;
                        })}
                    </ul>
                </div>
                {/* <div className='mt-8'>
                    <h3>添加日期</h3>
                    <p>{word.date}</p>
                </div>  */}
            </div>
        }
        
    }
    return (
       <div className="w-[200px] border-l-[1px]">
        <div className='top-bar'></div>
        {asideInfo()}
       </div>
    )
}

export default Aside;
