import {useEffect, useState, useRef} from 'react'
import React, { useContext } from 'react';
import {StateContext} from '../../store/contexts';
import { getWords, updateWord } from '../../api/api';
import {PlusCircleOutlined, EditOutlined, DeleteOutlined, DiffOutlined} from '@ant-design/icons'
import {Spin, Skeleton, Tooltip, Empty, message} from 'antd'
import WordTools from './components/WordTools'
import {wordStatusMap} from'../../constants/word-status'

const Content: React.FC<{}> = () => {
    const [wordsNum, setWordsNum] = useState(0);
    const {state, dispatch} = useContext(StateContext);
    const [pronounceWord, setPronounceWord] = useState('hello');
    const [firstLoad, setFirstLoad] = useState(true);
    const [currentOverWord, setCurrentOverWord]:any = useState('');
    const audioRef = useRef(null);
   
    const {
      chapter: {
        currentChapter: {
          name, 
          _id:chapter_id
        },
        chapterLoading
      },
      book: {
        currentBook: {
          _id: book_id
        },
        bookLoading,
      },
      word: {
        wordLoading,
        mode,
        currentWordList,
        filterWordList,
        currentWord,
        wordRefresh
      },
      config: {isInit},
      wordTools
    } = state;

    useEffect(() => {
      if(chapter_id) {
          getWords({book_id, chapter_id}).then((res:any) => {
              setWordsNum(res.result.length);
              setFirstLoad(false)
              dispatch({type: 'CURRENT_WORD_LIST', payload: res.result});

              const filterWord = wordTools['word-status'] < 0 ? 
              res.result :
              res.result.filter((item: any) => {
                return item.status === wordTools['word-status'];
              })

              dispatch({type: 'FILTER_WORD_LIST', payload: filterWord});
              dispatch({type: 'WORD_LOADING', payload: false})
              if(isInit) {
                dispatch({type:'IS_INIT', payload: false})
              }
          })
      }
      
    }, [chapter_id, wordRefresh]);

    // 单词发音
    const wordPlay =(word: string) => {
      setPronounceWord(word);
      setTimeout(() => {
        (audioRef as any).current.play()
      }, 50)
    }

    // 点击单词
    const wordClickEvent = (item: any) => {
      wordPlay(item.word)
      dispatch({type:"CURRENT_WORD", payload:item});
    }

    // 鼠标滑过单词
    const wordOverEvent = (word: any) => {
      setCurrentOverWord(word);
      wordPlay(word.word)
    }

    // 单词提示
    const wordTip = (word: any) => {
      return <div className='text-white pb-4'>
        <div className=''>
          <h2 className='text-white'>{word.word}</h2>
          <p className='mt-4'>[{word.phonetic}]</p>
        </div>
        <ul className='mt-6'>{
          word.explains.map((item: any) => {
            return <li>{item}</li>
          })
          }</ul>
      </div>
    }

    // 修改单词状态
    const updaeWordStatusEvent = (status: number) => {
      const {_id} = currentOverWord; 
      const newCurrentWordList = JSON.parse(JSON.stringify(currentWordList)).map((item:any) => {
        if(item._id === _id) {
          item.status = status;
        }
        return item;
      })
      dispatch({type:'CURRENT_WORD_LIST', payload: newCurrentWordList})

      const newFilterWordList = JSON.parse(JSON.stringify(filterWordList)).map((item:any) => {
        if(item._id === _id) {
          item.status = status;
        }
        return item;
      })

      dispatch({type:'FILTER_WORD_LIST', payload: newFilterWordList})

      const newCurrentWord = JSON.parse(JSON.stringify(currentOverWord));
      newCurrentWord.status = status;
      dispatch({type:'CURRENT_WORD', payload: newCurrentWord})

      updateWord({_id, status}).then((res: any) => {
        if(res.code !== 0) {
          message.success('状态更新失败')
        }
      })
    }

    // 单词状态
    const wordStatusTip = () => {
      return <div className='flex justify-between p-5'>
        <button onClick={() => {updaeWordStatusEvent(1)}} className="bg-red-500  text-white hover:opacity-[0.7]  rounded-md px-4 py-2 mr-4">不认识</button>
        <button onClick={() => {updaeWordStatusEvent(2)}} className="bg-yellow-400  text-white hover:opacity-[0.7] rounded-md px-4 py-2 mr-4">模糊</button>
        <button onClick={() => {updaeWordStatusEvent(3)}} className="bg-emerald-600  text-white hover:opacity-[0.7]  rounded-md px-4 py-2 ">认识</button>
      </div>
    }

    // 单词列表渲染
    const words = () => {
      const wordStatusColor = {
        1: 'border-red-600 border-[1px] text-red-600',
        2: 'border-yellow-600 border-[1px] text-yellow-600',
        3: 'border-emerald-700 border-[1px] text-emerald-700'
      }
      const list = (filterWordList as any).map((item:any) => {
       const classes = `relative mr-4 mb-4 px-2  py-2 bg-gray-100 rounded-md cursor-pointer ${(wordStatusColor as any)[item.status] ? (wordStatusColor as any)[item.status] : 'border-gray-100 border-[1px]'}`;
        return <Tooltip color={mode === 'view' ? 'black': 'white'} title={mode === 'view' ? wordTip(item) : wordStatusTip()} style={{opacity: 1}}>
            <li  onMouseOver={() => {wordOverEvent(item)}} onClick={() => {wordClickEvent(item)}} key={item._id} className={classes}>
            {item.word}
              { 
              item._id === currentWord._id ? <span className="right-[-4px] top-[-4px] animate-ping absolute bg-sky-400 opacity-75 h-2 w-2 rounded-full"></span> : null
              }
            </li>
          </Tooltip>
      })
      if(!filterWordList.length && wordTools['word-status'] < 0) {
        return <div className='mt-40'><Empty description="该章节暂无单词"/></div>
      }
      if(!filterWordList.length && wordTools['word-status'] > 0) {
        return <div className='mt-40 flex justify-center'><Empty 
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        description={
          <div>
            <span className="font-bold">
            {(wordStatusMap as any)[wordTools['word-status']]}
          </span>
          <span> 状态无单词</span>
          </div>
        }
        /></div>
      }
      return <ul className='flex flex-wrap justify-between'>{list}<li className='flex-1'></li></ul>
    }

    const updateMode = (mode: string) => {
      const payload = mode === 'view' ? 'memory' : 'view';
      dispatch({type: 'WORD_MODE', payload});
    }

    const modes = (mode: any) => {
      const modeData = {
        view: {
          label: '预览模式',
          icon: 'icon-liulan',
          color: 'bg-sky-600'
        },
        memory: {
          label: '背诵模式',
          icon: 'icon-jiyi',
          color: 'bg-red-600'
        }
      }
      return <button  onClick={() => {updateMode(mode)}} className={`${(modeData as any)[mode].color} p-4 text-white flex items-center`}>
        <span className={`iconfont ${(modeData as any)[mode].icon}`}></span>
        <span className='ml-2'>{(modeData as any)[mode].label}</span>
      </button>

    }

    return (
       <div className='flex-1'>
         <div className='top-bar flex flex-col'>
            <div className='flex-1 flex items-center'>
              <span className='flex-1'>
                { (wordLoading || chapterLoading || bookLoading) ? 'words on loading ...' : `${name} (${wordsNum} words)`}
              </span>
              <div>{modes(mode)}</div>
            </div>
            <div className='flex-1 border-t-[1px] border-slate-100 flex items-center'>
              <WordTools/>
            </div>
          
         </div>
          <div className='p-16 h-[calc(100vh-80px)] overflow-auto'>
            {(wordLoading || chapterLoading || bookLoading) ? 
            <div className='w-[90%]'>
              <Skeleton className='mb-10' active />
              <Skeleton className='mb-10' active />
              <Skeleton active />
            </div> :
            words()}
            <audio ref={audioRef}  src={`https://dict.youdao.com/dictvoice?type=1&audio=${pronounceWord}`}>11</audio>
          </div>
          <Spin spinning={state.word.wordLoading} className="pl-16"> </Spin>
      </div>
    )
}

export default Content;
