import React, { useState,useContext, useRef,useEffect } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import {addBook, updateBook} from '../../../api/index';
import {StateContext} from '../../../store/contexts';

interface BookFormProps {
    type: string;
}
const BookForm: React.FC<BookFormProps> = ({type}) => {
    const {state, dispatch} = useContext(StateContext);
    const formData = state.book.currentOperationalBook;

    const ref = useRef(null);
    const validateMessages = {
        required: "${label} is required!",
    };

    const formConfirm = (values: any) => {
        const newBook = {
            user_id: state.user.user_id,
            ...state.book.currentOperationalBook,
            ...values
        };
        if (type === 'add') {
            addBook(newBook).then((res:any) => {
                if(res.code === 0) {
                    message.success('add success');
                    dispatch({type: "MODAL_VISIBLE", payload:false})
                    dispatch({type: "BOOK_REFRESH", payload: + new Date()});
                } else {
                    
                }
            })
        } else {
            updateBook(newBook).then((res: any) => {
                if(res.code === 0) {
                    message.success('update success')
                    dispatch({type: "BOOK_REFRESH", payload: + new Date()});
                    dispatch({type: "MODAL_VISIBLE", payload: false});
                } else {
                    
                }
            })
        }
       
    }
    useEffect(() => {
        if (type === 'edit') {
          (ref.current as any).resetFields(); // 必须加 不然切换编辑 表单不更新
        }
  }, [type, state.book.currentOperationalBook.key])
    return (
        <>
            <Form ref={ref} onFinish={formConfirm} validateMessages={validateMessages}>
                <Form.Item name={'icon'} initialValue={formData['icon']} label="icon" rules={[{ required: true }]}>
                    <Input  />
                </Form.Item>
                <Form.Item name={'key'} initialValue={formData['key']} label="key" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={'title'} initialValue={formData['title']} label="名称" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button size='large' type="primary" htmlType="submit">
                        {type === 'edit' ? '确认修改' : '添加'}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default BookForm;