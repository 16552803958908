export const toolsMap = {
    'add-word': {
        title: '新增',
        color:'text-red-500',
        list: [
            {
                label: '新增单词',
                icon: 'icon-add-fill',
                color: '',
                event: 'addSingleWordEvent'
            },
            {
                label: '批量新增单词',
                icon: 'icon-piliangcaozuo',
                color: '',
                event: 'addMultiWordsEvent'
            }
        ]
    },
    'word-status':{
        title: '单词状态',
        color:'text-red-500',
        list: [
            {
                label: '全部',
                key: -1,
                icon: 'icon-icon_status-dot',
                color: 'gray-1',
                event: 'statusClickEvent'
            },
            {
                label: '未开始',
                key: 0,
                icon: 'icon-icon_status-dot',
                color: 'gray-2',
                event: 'statusClickEvent'
            },
            {
                label: '不认识',
                key: 1,
                icon: 'icon-icon_status-dot',
                color: 'red-1',
                event: 'statusClickEvent'
            },
            {
                label: '模糊',
                key: 2,
                icon: 'icon-icon_status-dot',
                color: 'yellow-1',
                event: 'statusClickEvent'
            },
            {
                label: '认识',
                key: 3,
                icon: 'icon-icon_status-dot',
                color: 'green-1',
                event: 'statusClickEvent'
            }
        ]
    },
    'word-layout':{
        title: '单词状态',
        color:'text-red-500',
        list: [
            {
                label: '块级排列',
                key: 'block',
                icon: 'icon-dasuolvetuliebiao',
                color: 'gray-1',
                event: 'layoutClickEvent'
            },
            {
                label: '表格',
                key: 'table',
                icon: 'icon-wuxupailie',
                color: 'gray-1',
                event: 'layoutClickEvent'
            }
        ]
    }
}

export const toolsSort = ['word-status', 'add-word']