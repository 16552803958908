import React, {useContext, useRef,useEffect } from 'react';
import {Button, Form, Input, message } from 'antd';
import {addChapter, updateChapter} from '../../../api/api';
import {StateContext} from '../../../store/contexts';

interface BookFormProps {
    type: string;
}
const ChapterForm: React.FC<BookFormProps> = ({type}) => {
    const {state, dispatch} = useContext(StateContext);
    const formData = state.chapter.currentOperationalChapter;

    const ref = useRef(null);

    const validateMessages = {
        required: "${label} is required!",
    };

    const formConfirm = (values: any) => {
        const newChapter = {
            book_id: state.book.currentBook._id,
            ...state.chapter.currentOperationalChapter,
            ...values
        };
        if (type === 'add') {
            addChapter(newChapter).then((res:any) => {
                if(res.code === 0) {
                    message.success('add success');
                    dispatch({type: "MODAL_VISIBLE", payload:false})
                    dispatch({type: "CHAPTER_REFRESH", payload: + new Date()});
                } else {
                    
                }
            })
        } else {
            updateChapter(newChapter).then((res: any) => {
                if(res.code === 0) {
                    message.success('update success')
                    dispatch({type: "MODAL_VISIBLE", payload: false});
                    dispatch({type: "CHAPTER_REFRESH", payload: + new Date()});
                    
                } else {
                    
                }
            })
        }
       
    }
    useEffect(() => {
        if (type === 'edit') {
          (ref.current as any).resetFields(); // 必须加 不然切换编辑 表单不更新
        }
  }, [type, state.chapter.currentOperationalChapter.key])
    return (
        <>
            <Form ref={ref} onFinish={formConfirm} validateMessages={validateMessages}>
                <Form.Item name={'icon'} initialValue={formData['icon']} label="icon" rules={[{ required: true }]}>
                    <Input  />
                </Form.Item>
                <Form.Item name={'key'} initialValue={formData['key']} label="key" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={'name'} initialValue={formData['name']} label="名称" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={'source'} initialValue={formData['source']} label="来源" rules={[{ required: false }]}>
                    <Input />
                </Form.Item>
                
                <Form.Item>
                    <Button size='large' type="primary" htmlType="submit">
                        {type === 'edit' ? '确认修改' : '添加'}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default ChapterForm;