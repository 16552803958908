import axios, { AxiosError} from "axios";
import host from './host';
interface Objects {
    [props: string]: any;
}
//axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded'
// 请求拦截器
axios.interceptors.request.use((config) => {
    config.url = host + config.url;
    return config;
})

// 响应拦截器
axios.interceptors.response.use((response) => {
    return response;
},(error)=>{
    return Promise.reject(error);
})

const request = (method: string, url: string, params: Objects) => {
    return new Promise((resolve, reject) => {
        (axios as any)[method](url, params).then((res: any) => {
                resolve(res.data);        
        }).catch((err: AxiosError)=> {
            reject(err);
        })
    })
}

export const get = (url: string, params: Objects) => {
    
    return request('get', url, params)
}

export const post = (url: string, params: Objects) => {
    return request('post', url, params)
}

export const put =(url: string, params: Objects) => {
    return request('put', url, params)
}

export const del =(url: string, params: Objects) => {
    return request('delete', url, params)
    
}
